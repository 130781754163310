import React from 'react';
import { Disclosure } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import MobileNavigationUserContent from '../MobileNavigationUserContent';

const MobileNavigationContent = ({ open, navigation }) => {
    return (
        <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                    <NavLink
                        key={item.name}
                        to={item.href}
                        className={(isActive) =>
                            classNames(
                                isActive
                                    ? 'text-gray-300 hover:bg-gray-700 hover:text-white'
                                    : 'bg-gray-900 text-white',
                                'block px-3 py-2 rounded-md text-base font-medium'
                            )
                        }
                    >
                        <Disclosure.Button>{item.name}</Disclosure.Button>
                    </NavLink>
                ))}
            </div>
            <MobileNavigationUserContent />
        </Disclosure.Panel>
    );
};

MobileNavigationContent.propTypes = {};

export default MobileNavigationContent;
